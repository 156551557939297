  <template>
  <v-row class="mt-n4">
    <v-col>
      <v-row v-if="!dispute.invoices.length">
        <v-col cols="3">
          <currencies
            :currency.sync="currency"
            :disabled="statusWillCloseDispute"
            :accountId.sync="accountId"
          />
        </v-col>
        <v-col>
          <amount-field
            :currency.sync="currency"
            :rules="required"
            :amount.sync="amount"
            :label="$t('t.AmountDisputed')"
            class="ml-2"
            :disabled="statusWillCloseDispute"
            reverse
          >
          </amount-field>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <invoice-form-table
            :headers="headers"
            :items.sync="invoices"
            :disabled="!dispute.canUpdate || statusWillCloseDispute"
            :editAmountOnly="invoiceReadOnly"
          />
        </v-col>
      </v-row>
      <v-row v-if="!invoiceReadOnly">
        <v-col>
          <v-expansion-panels
            accordion
            focusable
          >
            <v-expansion-panel v-show="dispute.canUpdate && !statusWillCloseDispute">
              <v-expansion-panel-header class="text-h6">{{$t('t.SelectInvoiceDisputed')}}</v-expansion-panel-header>
              <v-expansion-panel-content class="background-plain">
                <dispute-invoices
                  class="document-list"
                  :dispute="dispute"
                  :invoices.sync="invoices"
                  @row-action="computeAmount"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    DisputeInvoices: () => import('./dispute-invoices'),
    AmountField: () => import('@/components/amount-field'),
    InvoiceFormTable: () => import('@/components/invoice-form-table'),
    Currencies: () => import('@/components/currencies')
  },
  data () {
    return {
      headers: [
        { text: this.$t('t.Number#'), value: 'number' },
        { text: this.$t('t.Date'), value: 'date' },
        { text: this.$t('t.AmountInDispute'), value: 'amountDisputed', align: 'right' },
        { text: this.$t('t.Amount'), value: 'amount', align: 'right' },
        { text: this.$t('t.Balance'), value: 'balance', align: 'right' },
        { text: '', value: 'remove' }
      ],
      required: [
        v => Number.isFinite(v) || this.$t('t.IsRequired')
      ]
    }
  },
  computed: {
    amount: {
      get: function () {
        return this.dispute.amount
      },
      set: function (amount) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { amount }))
      }
    },
    currency: {
      get: function () {
        return this.dispute.currency
      },
      set: function (currency) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { currency }))
      }
    },
    invoices: {
      get: function () {
        return this.dispute.invoices
      },
      set: function (invoices) {
        this.$nextTick(() => { this.computeAmount() })
        this.$emit('update:dispute', Object.assign({}, this.dispute, { invoices, currency: invoices[0]?.currency || this.$store.getters.currentUser.currency }))
      }
    },
    invoiceReadOnly () {
      return !this.$store.getters.currentUserHasPermission('Invoices')
    },
    accountId () {
      return this.dispute.accountId
    }
  },
  methods: {
    computeAmount () {
      this.$nextTick(() => { this.amount = this.invoices.reduce((acc, i) => acc + i.amountDisputed, 0) })
    }
  },
  props: {
    dispute: Object,
    statusWillCloseDispute: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.minimum-width
  min-width max-content
  max-width max-content

.document-list
  height 400px
</style>
